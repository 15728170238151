.App {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.TwoSide {
    min-width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}

.TwoSide #Left {

}

.TwoSide #Right {
    width: fit-content;
}
